<template>
  <p>Logs</p>
</template>

<script>
import { setCurrentPageBreadcrumbs } from "../../core/helpers/breadcrumb";

export default {
  name: "Logs",
  setup() {
    setCurrentPageBreadcrumbs("Logs", []);
  }

}
</script>

<style scoped>

</style>